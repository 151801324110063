/* jshint esversion: 6 */
/* jshint esversion: 8 */
import { useEffect } from 'react';
import axios from 'axios';

const Khalti_Payment = ({ Amount }) => {

  const handlePayment = async () => {
    await axios.post('https://gomeet.cscodetech.cloud/react_khalti/index.php',
      {
        "amt": Amount
      }
    )
      .then((res) => {
        console.log("Respons midtrans : ", res);

        if (res.data.Result === "true") {
          window.location.href = res.data.payment_url;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!Amount) return;
    handlePayment();
  }, [Amount]);

  return null;
};

export default Khalti_Payment;
