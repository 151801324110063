/* jshint esversion: 6 */
/* jshint esversion: 8*/
import axios from 'axios';
import { useState, useEffect, useRef } from 'react';

const Onesignal = ({ Id, message, receiverId, channel, title, img, type }) => {

    const [notificationSent, setNotificationSent] = useState(false);
    const Test = useRef(false);

    const sendPushNotification = async () => {
        if (Test.current) {
            return;
        }

        var Data;

        if (type === "Audio") {
            Data = {
                Audio: channel,
                name: title,
                popic: img,
                id: Id
            };
        } else {
            Data = {
                vcId: channel,
                name: title,
                popic: img,
                id: Id
            };
        }

        if (Data) {
            try {
                await axios.post('https://onesignal.com/api/v1/notifications', {
                    app_id: '94b2b6c5-fabb-4454-a2b7-75cf75b84789',
                    filters: [
                        { field: 'tag', key: 'user_id', value: receiverId }
                    ],
                    headings: {
                        en: title
                    },
                    contents: {
                        en: message,
                    },
                    data: Data,
                    content_available: false,
                }, {
                    headers: {
                        Authorization: 'os_v2_app_sszlnrp2xncfjivxoxhxlochrelsh3ri352efxmhy5xhzirj7rn3ky7ulmeesdx6kgnyz4z7xvrmw6ach4qrz6qkygi3xlljxlxn6wy',
                    },
                })
                    .then((res) => {
                        console.log('Notification sent successfully', res);
                        setNotificationSent(true);
                    });
            } catch (error) {
                console.error('Error sending notification:', error);
            }
        }
    };

    useEffect(() => {
        if (message && receiverId && !notificationSent) {
            sendPushNotification();
            Test.current = true;
        }
    }, [message, receiverId, notificationSent]);

    return null;
};

export default Onesignal;
