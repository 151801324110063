/* jshint esversion: 6 */
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { MyContext } from '../Context/MyProvider';
import { useNavigate } from 'react-router-dom';

const PaytmPayment = ({ Amount }) => {

  const { setPayClose, setPlanId, setTransactionId, page, setBuyCoin } = useContext(MyContext);

  const [paymentData, setPaymentData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const Data = localStorage.getItem("Register_User");
    if (!Amount && !Data) return;
    const Userid = JSON.parse(Data);
    axios.post("https://gomeet.cscodetech.cloud/react_paytm/index.php",
      {
        "amt": Amount,
        "uid": Userid.id
      }
    )
      .then((res) => {
        if (res.data.Result == "true") {
          setPaymentData(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [Amount]);

  useEffect(() => {
    if (!paymentData) return;
    makePayment();
  }, [paymentData]);

  const makePayment = () => {
    var config = {
      "root": "",
      "style": {
        "bodyBackgroundColor": "#fafafb",
        "bodyColor": "",
        "themeBackgroundColor": "#0FB8C9",
        "themeColor": "#ffffff",
        "headerBackgroundColor": "#284055",
        "headerColor": "#ffffff",
        "errorColor": "",
        "successColor": "",
        "card": {
          "padding": "",
          "backgroundColor": ""
        }
      },
      "data": {
        "orderId": paymentData.order_id,
        "token": paymentData.txnToken,
        "tokenType": "TXN_TOKEN",
        "amount": paymentData.amount  /* update amount */
      },
      "payMode": {
        "labels": {},
        "filter": {
          "exclude": []
        },
        "order": [
          "CC",
          "DC",
          "NB",
          "UPI",
          "PPBL",
          "PPI",
          "BALANCE"
        ]
      },
      "website": "WEBSTAGING",
      "flow": "DEFAULT",
      "merchant": {
        "mid": paymentData.mid,
        "redirect": false
      },
      "handler": {
        "transactionStatus":
          function transactionStatus(paymentStatus) {
            console.log(paymentStatus);
            if (page === "Upgrade") {
              setPlanId("PaymentDone");
              navigate("/upgrade");
              setTransactionId(paymentStatus.BANKTXNID);
            } else if (page === "Wallet") {
              setPayClose("PaymentDone");
              navigate("/wallet");
            } else {
              setBuyCoin("PaymentDone");
              navigate("/buyCoin");
            }
            setPaymentData();
          },
        "notifyMerchant":
          function notifyMerchant(eventName, data) {
            console.log("Closed");
            // if (page === "Upgrade") {
            //   setPlanId("PaymentNotDone");
            // } else if (page === "Wallet") {
            //   setPayClose("PaymentNotDone");
            // } else {
            //   setBuyCoin("PaymentNotDone");
            // }
          }
      }
    };

    if (window.Paytm && window.Paytm.CheckoutJS) {
      window.Paytm.CheckoutJS.init(config)
        .then(function onSuccess() {
          window.Paytm.CheckoutJS.invoke();
        }).catch(function onError(error) {
          console.log("Error => ", error);
        });
    } else {
      console.log("Paytm CheckoutJS not loaded");
    }

  };

  return null;
};

export default PaytmPayment;

